import { gql } from "@apollo/client"

const getUserRole = gql`
  query getUserRole($userId: ID!) {
    user(id: $userId) {
      roles {
        nodes {
          name
          capabilities
        }
      }
    }
  }
`

export default getUserRole
